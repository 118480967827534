import React, { FunctionComponent } from "react";
import { RouteComponentProps } from "@reach/router";
import GatsbyImage, { FluidObject } from "gatsby-image";

import { useStaticQuery, graphql } from "gatsby";

import Layout from "../components/Layout";
import ContactForm from "../components/ContactForm";
import styles from "../styles/pages/contactos.module.scss";
import Flex from "../components/Flex";
import TextCard from "../components/TextCard";
import { useIntl } from "gatsby-plugin-intl";
import SEO from "../components/SEO";

const ContactPage: FunctionComponent<RouteComponentProps> = () => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query {
      yellowSquares: file(
        relativePath: { eq: "backgrounds/yellow-squares.svg" }
      ) {
        publicURL
      }
      background: file(relativePath: { eq: "backgrounds/contactPage.jpg" }) {
        publicURL
        childImageSharp {
          fluid(quality: 90) {
            src
            tracedSVG
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: "seo_title_contactos" })}
        description={intl.formatMessage({ id: "seo_description_contactos" })}
        keywords={intl.formatMessage({ id: "seo_keywords_contactos" })}
        canonical_url={`https://take-eat.pt/contactos`}
        image={`https://take-eat.pt${data.background?.publicURL}`}
      />
      <div className={styles.contactWrapper}>
        <Flex
          flexDirection="row"
          alignItems="center"
          justifyContent="between"
          className={styles.contactFormSection}
        >
          <Flex flexDirection="column" className={styles.colContactLeft}>
            <ContactForm />
          </Flex>
          <Flex
            flexDirection="column"
            style={{ position: "relative" }}
            className={styles.colContactRight}
          >
            <div className={styles.contactCard}>
              <TextCard>
                <Flex flexDirection="row">
                  <h2 className={styles.titleContactCard}> MOSANO </h2>
                </Flex>
                <Flex flexDirection="row">
                  <Flex
                    flexDirection="column"
                    className={styles.colContactCard}
                  >
                    <h3 className={styles.subTitleContactCard}>
                      {intl.formatMessage({ id: "contactPage_label_contacts" })}
                    </h3>
                    <a
                      href="mailto:hello@mosano.eu"
                      className={styles.textContactCard}
                    >
                      {" "}
                      hello@mosano.eu{" "}
                    </a>
                    <a
                      href="tel:+351914143912"
                      className={styles.textContactCard}
                    >
                      {" "}
                      +351 914143912{" "}
                    </a>
                  </Flex>
                  <Flex
                    flexDirection="column"
                    className={styles.colContactCard}
                  >
                    <h3 className={styles.subTitleContactCard}>
                      {" "}
                      {intl.formatMessage({
                        id: "contactPage_label_address",
                      })}{" "}
                    </h3>
                    <p className={styles.textContactCard}>
                      {" "}
                      Rua D. João I, nº 256{" "}
                    </p>
                    <p className={styles.textContactCard}>
                      {" "}
                      4450-162, Matosinhos{" "}
                    </p>
                    <p className={styles.textContactCard}> Portugal </p>
                  </Flex>
                </Flex>
              </TextCard>
            </div>
            <div className={styles.backgroundMesh}>
              <img
                className={styles.yellowSquares}
                src={data.yellowSquares?.publicURL}
                alt="yellowSquares image"
              />
              <div className={styles.backgroundImage}>
                <GatsbyImage
                  fadeIn
                  alt=""
                  fluid={data.background?.childImageSharp.fluid}
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                    zIndex: -1,
                    margin: 0,
                  }}
                />
              </div>
              {/* <img
                className={styles.backgroundImage}
                src={data.background?.publicURL}
                alt="background image"
              /> */}
            </div>
          </Flex>
        </Flex>
      </div>
    </Layout>
  );
};

export default ContactPage;
