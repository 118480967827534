import React, { FunctionComponent } from "react";

import styles from "./styles.module.scss";

const TextCard: FunctionComponent = ({ children }) => (
  <div className={styles.cardWrapper}>
    <div className={styles.content}>{children}</div>
    <div className={styles.floatingBorder} />
  </div>
);

export default TextCard;
