import React, { FunctionComponent } from "react";
import styles from "./styles.module.scss";
import Button from "../../components/Button";
import { useIntl } from "gatsby-plugin-intl";

interface ContactProps {
  title?: string;
  text?: string;
}
const ContactForm: FunctionComponent<ContactProps> = ({ title, text}) => {
  const intl = useIntl();
  return (
    <div>
      <div>
        <h1 className={styles.contactTitle}>{intl.formatMessage({id: "contactForm_title"})}</h1>
        <p className={styles.contactText}>{intl.formatMessage({id: "contactForm_subTitle"})}</p>
      </div>
      <form 
        className={styles.contactForm}
        action="https://formspree.io/info@mosano.eu"
        method="POST"
      >
        <label className={styles.labelCol}>
          <p className={styles.labelStyle}>{intl.formatMessage({id: "contactForm_Name"})}*</p>
          <input className={styles.inputStyle} type="text" name="name"/>
        </label>
        <label className={styles.labelCol}>
          <p className={styles.labelStyle}>{intl.formatMessage({id: "contactForm_Email"})}*</p>
          <input className={styles.inputStyle} type="text" name="email" />
        </label>
        <label className={styles.labelCol}>
          <p className={styles.labelStyle}>{intl.formatMessage({id: "contactForm_Phone"})}</p>
          <input className={styles.inputStyle} type="text" name="phone" />
        </label>
        <label className={styles.labelCol}>
          <p className={styles.labelStyle}>{intl.formatMessage({id: "contactForm_Company"})}*</p>
          <input className={styles.inputStyle} type="text" name="company" />
        </label>
        <Button primary style={{maxWidth:"215px", marginTop:"32px"}}>
         {intl.formatMessage({id: "contactForm_button_label"})}
          <input type="submit" value="send" style={{ display: "none"}} />
        </Button>
      </form>
    </div>
  )
};

export default ContactForm;
