import React, { FunctionComponent, ButtonHTMLAttributes } from "react";

import classNames from "classnames";

import Icon from "../Icon";

import styles from "./styles.module.scss";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  small?: boolean;
  loading?: boolean;
  primary?: boolean;
  secondary?: boolean;
  minWidth?: boolean;
}

const Button: FunctionComponent<ButtonProps> = ({
  small,
  loading,
  primary,
  secondary,
  minWidth,
  className,
  children,
  ...rest
}) => (
  <button
    className={classNames(
      styles.button,
      {
        [styles.buttonSmall]: small,
        [styles.buttonLoading]: loading,
        [styles.buttonPrimary]: primary,
        [styles.buttonSecondary]: secondary,
        [styles.buttonNoMinWidth]: !minWidth,
      },
      className
    )}
    {...rest}
  >
    {loading ? <Icon size={14} name="loading" spin={loading} /> : children}
  </button>
);

Button.defaultProps = {
  small: false,
  loading: false,
  primary: false,
  secondary: false,
  minWidth: true,
};

export default Button;
